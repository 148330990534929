import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { AUTH_PROVIDERS } from '@app/core/services/auth/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//import { CoreModule} from '@app/core';
//import { ErrorsModule} from '@app/core/errors';
import { ApiService } from '@app/core/services/http/api.service';
import { LayoutModule } from '@app/shared/layout';
import { VdsNgkitModule } from '@app/lib/vades-ngkit';
import { VdsHttpErrorsInterceptor, VdsErrorsHandler } from '@app/core/errors';


import { OAuthModule, OAuthStorage, AuthConfig, ValidationHandler, JwksValidationHandler } from 'angular-oauth2-oidc';
import { oauthConfig } from '@app/config/oauth.config';


@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    //CoreModule,
    LayoutModule,
    VdsNgkitModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://id.aeotec.com/api'],
        sendAccessToken: true
      }
    })
    //ErrorsModule,
  ],
  providers: [
    Title, ApiService,
    AUTH_PROVIDERS,
    //TODO: handler show file and line in error service
    // We need to show error from origin file and line
    /*  {
       provide: ErrorHandler,
       useClass: VdsErrorsHandler
     }, */
    { provide: OAuthStorage, useValue: localStorage },
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    { provide: AuthConfig, useValue: oauthConfig },
    { provide: HTTP_INTERCEPTORS, useClass: VdsHttpErrorsInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
