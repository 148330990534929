import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  userName: string = '';
  constructor(
     public authService: AuthService
  ) { }

  ngOnInit() {
    this.userName = this.authService.getUserName();
  }

}
