import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-aside-left',
  templateUrl: './aside-left.component.html',
  styles: []
})
export class AsideLeftComponent implements OnInit {
  env = environment;
  constructor() { }

  ngOnInit() {
  }

}
