import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', loadChildren: './features/auth/login/login.module#LoginModule'  },
  { path: 'dev', loadChildren: './features/dev/dev.module#DevModule',canActivate: [ AuthGuard ]  },
  { path: 'cruds', loadChildren: './features/cruds/cruds.module#CrudsModule' ,canActivate: [ AuthGuard ]  },
    { path: 'ngkit', loadChildren: './features/ngkit/ngkit.module#NgkitModule',canActivate: [ AuthGuard ]  },
  { path: 'dashboard', loadChildren: './features/dashboard/dashboard.module#DashboardModule' ,canActivate: [ AuthGuard ]  },
  { path: 'groups', loadChildren: './features/groups/groups.module#GroupsModule' ,canActivate: [ AuthGuard ]  },
  { path: 'statistics', loadChildren: './features/statistics/statistics.module#StatisticsModule' ,canActivate: [ AuthGuard ]  },
  { path: 'chart', loadChildren: './features/charts/chart.module#ChartModule',canActivate: [ AuthGuard ]   },
  { path: 'gateways', loadChildren: './features/gateways/gateways.module#GatewaysModule' ,canActivate: [ AuthGuard ]  },
  { path: 'settings', loadChildren: './features/settings/settings.module#SettingsModule',canActivate: [ AuthGuard ]   },
  { path: 'user', loadChildren: './features/user/user.module#UserModule',canActivate: [ AuthGuard ]   },
  { path: 'about', loadChildren: './features/pages/pages.module#PagesModule' ,canActivate: [ AuthGuard ]  },
  { path: 'logout', loadChildren: './features/auth/logout/logout.module#LogoutModule',canActivate: [ AuthGuard ]   },
  { path: 'auth', loadChildren: './features/auth/auth.module#AuthModule'  },
  { path: '**', loadChildren: './core/error404/error404.module#Error404Module',canActivate: [ AuthGuard ]  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
