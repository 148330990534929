import { AuthConfig } from 'angular-oauth2-oidc';

export const oauthConfig: AuthConfig = {
  showDebugInformation: true,
  // Url of the Identity Provider
  issuer: 'https://id.aeotec.com:8810/',
  // oidc: true,

  redirectUri: 'https://rcc.aeotec.com/',//window.location.origin,

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  // timeoutFactor: 0.2,
  // silentRefreshTimeout: 5000,
  // logoutUrl:  window.location.origin,
  postLogoutRedirectUri: 'https://rcc.aeotec.com', // window.location.origin,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: '6f145b41-4892-44f3-9e46-9838de39c3a1',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid offline_access profile force-consent'
}