import { Component, OnInit,Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';

import { VdsAlertService } from './vds-alert.service';


@Component({
  selector: 'vds-alert',
  template: `
  <section *ngIf="alert" [@slideInOut] [ngClass]="'alert ' + alert.status + ' ' + classList" (click)="clearAlert()">
  <i class="icon"></i>
  <header>{{alert.message}}</header>
  </section>
  `,
  styleUrls: ['./vds-alert.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-20%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-20%)'}))
      ])
    ])
  ]
})
export class VdsAlertComponent implements OnInit {
  @Input() classList: string = '';
  alert: any;
  alertSubscription: Subscription;

  public constructor(
    private alertService: VdsAlertService
  ) {
    // subscribe to component alerts
    this.alertSubscription = this.alertService.getAlert().subscribe(alert => { this.alert = alert; });
  }

  ngOnInit() {
  }

  // clear alert
  clearAlert(): void {
    this.alertService.clearAlert();
  }
  // unsubscribe to ensure no memory leaks
  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }

}
