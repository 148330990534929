import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-svg',
  template: `
<svg class="logo-svg-wrap" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.logo-svg{fill:#989fb4;}
</style>
<g id="g12" transform="matrix(0.29739202,0,0,-0.29739202,94.212165,779.18619)">
	<path id="path16" class="logo-svg" d="M-164.2,2594.4c4.2,3.6,9.4,6.1,14.8,5.9c6.3,0.4,12.5-2.1,17.3-5.9c7.1-5.9,14.2-11.7,21.3-17.6
		c1.7-1.3,3.3-2.5,5-3.6c0-2.5,0.2-4.8,0-7.3c-0.2-1.3-1-2.3-2.3-2.3c-2.1-0.2-4,0.8-5.6,1.9c-3.3,2.1-6.5,4.8-9.4,7.3
		c-4.8,4-9.6,7.9-14.4,11.9c-5.9,4.6-14,6.9-21.3,4.6c-3.8-1-6.9-3.3-9.6-5.9c-43.9-36.6-87.8-72.9-131.9-109.5
		c-1.7-1.5-3.3-3.1-4.2-5c-0.4-1,0-2.3,0.8-2.9c1.7-1,4-1,5.9-1.3c6.5,0,12.8,0,19.2,0c5.2,0.2,10.5-1.5,14.4-4.6
		c4-3.1,5.9-8.4,5.9-13.4c0-27.8,0-55.4,0-83.2c0-5.6,1.9-11.5,6.1-15.5c3.3-3.1,8.2-4.6,12.8-4.6c57.9,0,116,0,173.9,0
		c5,0,10.2,1.7,14,5c3.3,3.1,5.2,7.5,5.2,12.1c0.2,28.2,0.2,56.2,0.2,84.4c-0.2,6.9,2.9,14.4,9.4,17.8c4.4,2.5,9.4,1.7,14.2,2.1
		c5.4,0.2,10.9-0.6,16.3,0.8c1.9,0.4,4.4,1.7,4.2,4c-0.4,2.7-2.7,4.8-4.6,6.5c-15.9,13.4-32,26.5-48.1,39.9
		c-6.5,5-11.1,12.3-12.3,20.3c-0.6,7.1-0.2,14.4-0.2,21.5c0,4.8-1.5,9.8-5,13.2c-2.9,2.7-6.9,3.8-10.9,3.8c-4.4,0-8.8,0-13,0
		c0.2,2.7-0.6,5.6,0.6,8.2c7.5,0,15.3,0,22.8,0c3.6,0.2,7.3,0,10.5-1.9c5.6-2.9,8.6-9.4,8.8-15.7c0-6.5,0-12.8,0-19.2
		c-0.4-10.2,4.6-20.1,12.1-27c18.4-15.3,36.6-30.3,55-45.6c2.1-1.9,4.2-3.8,5.4-6.3c0.8-1.7,0.2-4-1.5-4.8
		c-4.8-2.7-10.5-1.7-15.7-1.9c-7.1-0.4-14.4,1-21.1-2.1c-6.7-3.3-10.2-10.9-10.7-18c-0.4-11.1-0.2-22.2-0.2-33.2
		c0-18.4,0-36.8,0-55.4c0-4.8,0.6-9.8-1.5-14.2c-3.1-7.5-11.5-11.9-19.4-11.7h-194.8c-4.4,0-8.8,1-12.1,3.8c-5,4-7.7,10.5-7.7,16.9
		c0,23.8,0,47.7,0,71.5c0,7.9,0.2,15.9-0.2,23.8c-0.4,4.6-2.1,9.4-5.6,12.5c-4.2,4-10.2,5.9-16.1,5.9c-6.9,0-13.6,0.2-20.5,0.2
		c-2.7,0-5.9,0-8.2,1.9c-1,1.3-0.6,2.9,0.2,4.4c1.3,2.1,2.9,3.8,4.8,5.2C-262.5,2512.9-213.3,2553.6-164.2,2594.4L-164.2,2594.4z"/>
	<path id="path18" class="logo-svg" d="M-136.4,2553c0.6,0,1,0,1.7,0c0.2-19-4.8-38.3-14.2-54.8c-9.8-17.3-24.5-32-42-42
		c-15.5-8.8-32.8-13.8-50.6-14.2c-1.5-0.2-3.1-0.2-4.6-0.2c0.4,4.4,4.4,7.7,8.8,8.2c14,1.5,27.8,4.8,40.5,10.5
		c16.5,7.3,30.9,19.2,41,34.1C-143.9,2511.8-138.1,2532.3-136.4,2553L-136.4,2553z"/>
	<path id="path20" class="logo-svg" d="M-99.8,2553.2c0.8,0,1.5,0,2.3,0c0.4-31.4-10-62.7-28.6-88c-10.5-14.2-23.4-26.5-38.3-36.2
		c-24-15.7-52.7-24.2-81.3-24.2c0.6,5,5,9.4,9.8,10.5c24,2.3,47.9,8.8,68.8,21.1c17.6,10.2,32.8,24.7,43.7,42
		C-109.2,2500.5-102.1,2526.9-99.8,2553.2z"/>
	<path id="path22" class="logo-svg" d="M-172.8,2553.2l0.8-0.4c0.4-10.7-2.1-21.3-6.5-30.9c-7.7-16.9-22.2-30.7-39.3-37.6
		c-9-3.8-18.6-5.6-28.2-5.4c0.6,2.7,3.1,5,5.9,5.2c15.7,1.7,31.4,6.9,43.5,17.6c12.1,10.5,19.6,25.5,22.4,41
		C-173.6,2546.1-173.2,2549.7-172.8,2553.2z"/>
</g>
</svg>
  `,
  styles: []
})
export class LogoSvgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
