import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev-menu',
  templateUrl: './dev-menu.component.html',
  styles: []
})
export class DevMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
