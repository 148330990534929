import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { AuthService } from '@app/core/services/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: []
})
export class AppComponent implements OnInit {
    env = environment;
    title = 'Dashboard';


    notification: string;
    showNotification: boolean;

    private isAuthenticated: Observable<boolean>;

    public constructor(
        private titleService: Title,
        public authService: AuthService,
        private notificationsService: NotificationsService,
    ) {
        this.isAuthenticated = authService.isAuthenticated$;

        notificationsService.changeEmitted$.subscribe(
            response => {
                console.info(response);
                this.notification = response;
                this.showNotification = true;

            });

        /**
         * init OAuth
         */
        this.authService.init();
    }

    ngOnInit() {
        this.titleService.setTitle(this.env.appName);
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle + this.env.appName);
    }
}
