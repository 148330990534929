// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'RCC',
  apiUrl: 'https://id.aeotec.com/',
  apiStorageUrl: 'https://id.aeotec.com/storage/',
  apiEndPoint: 'https://id.aeotec.com/api/v1/',
  api: {
    cruds: 'api/v1/rcc/cruds',
    notifications: 'api/v1/rcc/notifications',
    notificationsBlacklist: 'api/v1/rcc/notifications-blacklist',
    gateways: 'api/v1/rcc/gateways',
    gatewayDevices: 'api/v1/rcc/gateway-devices',
    gatewayProducts: 'api/v1/rcc/gateway-products',
    groups: 'api/v1/rcc/groups',
    settings: 'api/v1/rcc/settings',
    settingsEmail: 'api/v1/rcc/settings/email',
    settingsGateway: 'api/v1/rcc/settings/gateway',
    charts: 'api/v1/rcc/charts'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
