import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry,catchError } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth/auth.service';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class VdsHttpErrorsInterceptor {

  constructor(
    private authService: AuthService,
    private errorsService: ErrorsService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(retry(1),catchError(error => {
        if (error.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.logOut();
          location.reload(true);
      }
         this.errorsService.handleHttpError(error);
          return throwError(error);
      }))
  }
}
