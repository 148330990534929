import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map,catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { VdsNotifyService } from '@app/lib/ngkit/notify/vds-notify.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiToken = localStorage.getItem('api_token');
  private name = 'user_name';
  private apiEndPoint = environment.apiUrl
  private headers = new HttpHeaders()
  .set('Content-Type', 'application/json; charset=utf-8');
 /*  .set('Accept', 'application/json')
  .set('Authorization', 'Bearer ' + this.apiToken); */

  private httpOptions = {
    headers: this.headers
  };

  /*  private opt = new RequestOptions({
     headers: this.headers
   }); */
  constructor(
    protected http: HttpClient,
    private notifyService: VdsNotifyService
  ) { }
  /**
   * Get data
   * @param url 
   * @param options 
   */
  get(url: string, options?): Observable<any> {
    const apiUrl = this.apiEndPoint + url;
    console.log('ApiService GET request: ' + apiUrl);
    return this.http.get(apiUrl, this.httpOptions) 
    .pipe(
      map(response => response)
      );
  }
  /**
   * Post data
   * @param url 
   * @param options 
   */
  post(url: string, data: any): Observable<any> {
    const apiUrl = this.apiEndPoint + url;
    console.log('ApiService POST request: ' + apiUrl);
    return this.http.post(apiUrl, JSON.stringify(data), this.httpOptions)
    .pipe(
      map(response => {
        this.notifyService.sendNotify('Your data were successfully created.', 'is-info');
        return response;
      })
      );
  }
  /**
   * Put data
   * @param url 
   * @param data 
   */
  put(url: string, data: any = {}): Observable<any> {
    const apiUrl = this.apiEndPoint + url;
    console.log('ApiService PUT request: ' + apiUrl);
    return this.http
      .put(apiUrl, JSON.stringify(data), this.httpOptions)
      .pipe(
        map(response => {
          this.notifyService.sendNotify('Your data were successfully updated.', 'is-info');
          return response;
        })
        );
  }

  /**
 * Delete data
 * @param url 
 * @param data 
 */
  delete(url: string): Observable<any> {
    const apiUrl = this.apiEndPoint + url;
    console.log('ApiService DELETE request: ' + apiUrl);
    return this.http
      .delete(apiUrl, this.httpOptions)
      .pipe(
        map(response => {
          this.notifyService.sendNotify('Your data were successfully deleted.', 'is-info');
          return response;
        })
        );
  }

/**
 * Upload a file to the server
 * 
 * FIXME: Add this.httpOptions
 * @param url 
 * @param data 
 */
uploadFile(url: string, data: any): Observable<any> {
  const apiUrl = this.apiEndPoint + url;
  //const apiUrl = url;
  console.log('ApiService UPLOAD FILE request: ' + apiUrl);
  //return this.http.post(apiUrl, data, this.httpOptions)
  return this.http.post(apiUrl, data)
  .pipe(
    map(response => {
      this.notifyService.sendNotify('Your data were successfully uploaded.', 'is-info');
      return response;
    })
    );
}
  /**
   * Error handler
   * @param error 
   */
  handleError(error: Response | any) {
    if(error && error.status == 401){
      console.error('Logout because of status: ',error.status);
      //localStorage.clear();
      /* localStorage.removeItem('user_name');
    localStorage.removeItem('api_token'); */
    }
   return throwError(error.status);
  }
}
