import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, filter, map } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private oauthService: OAuthService,
        private router: Router,
        private authService: AuthService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {

        return this.authService.canActivateProtectedRoutes$
            .pipe(tap(status => {
                console.log('You tried to go to ' + state.url + ' and this guard said ' + status);
            }))
            .pipe(map(status => {
                if(status) {
                    let claims = this.oauthService.getIdentityClaims();
                    // check if the user verfied
                    if (claims && claims['user'].verified_at == null) {
                        window.location.href = 'https://id.aeotec.com/auth/verify';
                        return false;
                    }
                    // check if route restricted by role
                    if (next.data.roles && !next.data.roles.some(role => claims['user']['roles'].some(r => role.name == r))) {
                        this.router.navigate(['login']);
                        return false;
                    }

                    // authorized
                    return true;
                }
                this.router.navigate(['login']);
                return false;
            }));

    }

}
