import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { ApiService } from '@app/core/services/http/api.service';

import { map, filter } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';

import { Router, NavigationStart } from '@angular/router';

import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private token = 'api_token';
    private name = 'user_name';
    env = environment;

    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest(
        this.isAuthenticated$,
        this.isDoneLoading$
    ).pipe(map(values => values.every(b => b)));

    constructor(
        private apiService: ApiService,
        private oauthService: OAuthService,
        private router: Router
    ) {

        this.oauthService.events.subscribe(e => e instanceof OAuthErrorEvent ? console.error('oauth/oidc event', e) : console.warn('oauth/oidc event', e));

        this.oauthService.events
            .subscribe(_ => {
                this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
            });

        this.oauthService.events
            .pipe(filter(e => ['token_received'].includes(e.type)))
            .subscribe(e => this.oauthService.loadUserProfile());

        // This is tricky, as it might cause race conditions (where access_token is set in another
        // tab before everything is said and done there.
        // TODO: Improve this setup.
        window.addEventListener('storage', (event) => {
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                this.router.navigate(['/']);
            }
        });

        this.oauthService.setupAutomaticSilentRefresh();
    }

    /**
    * Login into the system.
    * @param data
    */
    /*logIn(data: object) {
        return this.apiService.post(this.loginUrl, data);
    }*/

    login() {
        this.oauthService.initImplicitFlow();
    }

    setUser(token: string, name: string): any {
        localStorage.setItem(this.name, name);
        localStorage.setItem(this.token, token);
        return;
    }

    getApiToken(): any {
        return localStorage.getItem(this.token);
    }

    getUserName(): any {
        let claims = this.oauthService.getIdentityClaims();
        let userName = claims['user']['first_name'] + ' ' + claims['user']['last_name']
        return userName;
    }

    isLoggedIn(): boolean {
        return this.getApiToken() !== null;
    }

    logOut(): any {
        localStorage.removeItem(this.name);
        localStorage.removeItem(this.token);
        return;
    }

    /**
     * [init description]
     * @return {Promise<void>} [description]
     */
    public init(): Promise<void> {
        return this.oauthService.loadDiscoveryDocument()
            .then(() => {
                this.oauthService.tryLogin();
            }).then(() => {
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }

                // 2. SILENT LOGIN:
                // Try to log in via silent refresh because the IdServer
                // might have a cookie to remember the user, so we can
                // prevent doing a redirect:
                return this.oauthService.silentRefresh()
                    .then(() => Promise.resolve())
                    .catch(result => {
                        // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                        // Only the ones where it's reasonably sure that sending the
                        // user to the IdServer will help.
                        const errorResponsesRequiringUserInteraction = [
                            'interaction_required',
                            'login_required',
                            'account_selection_required',
                            'consent_required',
                        ];

                        if (result
                            && result.reason
                            && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

                            // 3. ASK FOR LOGIN:
                            // At this point we know for sure that we have to ask the
                            // user to log in, so we redirect them to the IdServer to
                            // enter credentials.
                            //
                            // Enable this to ALWAYS force a user to login.
                            // this.oauthService.initImplicitFlow();
                            //
                            // Instead, we'll now do this:
                            console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                            return Promise.resolve();
                        }

                        // We can't handle the truth, just pass on the problem to the
                        // next handler.
                        return Promise.reject(result);
                    });
            }).then(() => {
                this.isDoneLoadingSubject$.next(true);

                if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                    console.log('There was state, so we are sending you to: ' + this.oauthService.state);
                    this.router.navigateByUrl(this.oauthService.state);
                }
            }).catch(() => this.isDoneLoadingSubject$.next(true));
    }

}

export const AUTH_PROVIDERS: Array<any> = [
    { provide: AuthService, useClass: AuthService }
];
